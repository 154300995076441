<template>
  <q-input
    :label="label"
    :rules="rules"
    :mask="mask"
    :disable="disabled"
    clear-icon="close"
    outlined
    dense
    clearable
    lazy-rules
    hide-bottom-space
  >
    <template v-slot:prepend v-if="icon">
      <q-icon :name="icon" size="xs" />
    </template>

    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy
          ref="dateRef"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            v-model="dateSelected"
            :options="options"
            :minimal="minimalCheck"
            :emit-immediately="minimalCheck"
            :default-view="defaultViewCheck"
            @update:model-value="checkValue"
          >
            <div class="row store-center justify-end">
              <q-btn v-close-popup label="Close" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import { ref, computed } from 'vue'

import { DATE } from '@/tools'

export default {
  name: 'FormDate',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    },
    mask: {
      type: String,
      default: 'date'
    },
    options: {
      type: Function,
      default: () => true
    },
    defaultView: {
      type: String,
      default: 'Calendar',
      validator: val => {
        return ['Calendar', 'Months', 'Years', 'YearMonth'].includes(val)
      }
    }
  },
  setup(props, { emit }) {
    const dateRef = ref(null)
    const dateSelected = ref(DATE.toFriendlyDate(props.value, '/'))
    const defaultViewCheck = computed(() => {
      return props.defaultView === 'YearMonth' ? 'Years' : props.defaultView
    })
    const minimalCheck = computed(() => {
      return (
        props.defaultView === 'Months' ||
        props.defaultView === 'Years' ||
        props.defaultView === 'YearMonth'
      )
    })

    const checkValue = (val, reason) => {
      emit('input', val)
      dateSelected.value = val

      if (props.defaultView === 'YearMonth' && reason === 'month') {
        dateRef.value.hide()
      } else if (props.defaultView === 'Years' && reason === 'year') {
        dateRef.value.hide()
      } else if (props.defaultView === 'Months' && reason === 'add-day') {
        dateRef.value.hide()
      } else if (props.defaultView === 'Calendar') {
        dateRef.value.hide()
      }
    }

    return {
      dateRef,
      defaultViewCheck,
      minimalCheck,
      dateSelected,
      checkValue
    }
  }
}
</script>
