<template>
  <q-select
    options-selected-class="bg-primary text-white"
    :label="label"
    :options="computedOptions"
    :rules="rules"
    :display-value="storeDisplay"
    :multiple="multiple"
    :loading="loading"
    :disable="disabled"
    emit-value
    map-options
    dense
    outlined
    clearable
    use-input
    fill-input
    hide-bottom-space
    input-debounce="0"
    clear-icon="close"
    dropdown-icon="expand_more"
    @add="addValue"
    @remove="removeValue"
    @filter="filterValue"
    @update:model-value="storeSelected"
  >
    <template v-slot:prepend v-if="icon">
      <q-icon :name="icon" size="xs" />
    </template>

    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-italic text-grey"> No data </q-item-section>
      </q-item>
    </template>

    <template #before-options v-if="multiple && !disabledSelectAll">
      <q-item :class="`${all ? 'bg-primary text-white' : ''}`">
        <q-item-section side>
          <q-checkbox :model-value="all" @update:model-value="checkAll" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Select All</q-item-label>
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
      <q-item v-bind="itemProps">
        <q-item-section side v-if="multiple">
          <q-checkbox
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label v-html="opt.label" />
          <q-item-label
            v-html="opt.description"
            v-if="opt.description"
            class="q-pl-sm"
            caption
          />
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import { onBeforeMount, ref, watch } from 'vue'

export default {
  name: 'FormSelect',
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    rules: {
      type: Array,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    values: {
      default: null,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Boolean,
      default: false
    },
    disabledSelectAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const computedOptions = ref(props.options)
    const store = ref([])
    const storeDisplay = ref('')
    const all = ref(false)

    const addValue = item => {
      store.value.push(item)

      if (store.value.length > 3) {
        storeDisplay.value = `${store.value.length} selected`
      } else {
        storeDisplay.value = store.value.forEach(item => item.value)
      }
    }

    const removeValue = item => {
      const index = store.value.findIndex(x => x.value == item.value)
      store.value.splice(index, 1)

      if (store.value.length > 3) {
        storeDisplay.value =
          store.value.length !== 0 ? `${store.value.length} selected` : ''
      } else {
        storeDisplay.value = store.value.forEach(item => item.value)
      }
    }

    const filterValue = (val, update) => {
      emit('customFilter', val)

      setTimeout(() => {
        update(() => {
          const needle = val?.toLowerCase()
          computedOptions.value = props.options.filter(
            v => v?.label?.toLowerCase().indexOf(needle) > -1
          )
        })
      }, 200)
    }

    const checkAll = v => {
      if (v) {
        store.value = props.rawData
          ? props.options
          : props.options.map(v => v.value)
        storeSelected()
        return
      }
      storeCleared()
    }

    const storeSelected = () => {
      emit('updateStore', store.value)

      if (props.multiple) {
        if (store.value && store.value.length === props.options.length) {
          all.value = true
        } else {
          all.value = false
        }

        if (store.value.length > 3) {
          storeDisplay.value = `${store.value.length} selected`
        } else {
          storeDisplay.value = store.value.forEach(item => item.value)
        }
      }
    }

    const storeCleared = () => {
      all.value = false
      store.value = []
      storeDisplay.value = ''
      storeSelected()
    }

    watch(
      () => props.options,
      () => {
        computedOptions.value = props.options
      }
    )

    watch(
      () => props.values,
      () => {
        if (!props.values || props.values?.length === 0) {
          all.value = false
          store.value = []
          storeDisplay.value = ''
        } else {
          computedOptions.value = props.options
          store.value = props.values
          storeSelected()
        }
      }
    )

    onBeforeMount(() => {
      if (props?.values?.length > 0) {
        computedOptions.value = props.options
        store.value = props.values
        storeSelected()
      }
    })

    return {
      store,
      computedOptions,
      storeDisplay,
      all,
      addValue,
      removeValue,
      filterValue,
      checkAll,
      storeSelected,
      storeCleared
    }
  }
}
</script>
