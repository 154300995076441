<template>
  <q-toggle
    checked-icon="check"
    unchecked-icon="clear"
    :color="variant"
    :label="label"
    :disable="disabled"
    keep-color
  />
</template>

<script>
export default {
  name: 'FormToggle',
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
