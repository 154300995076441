<template>
  <q-input
    :class="inputClass"
    :input-class="inputInnerClass"
    :type="inputType"
    :label="!inlineLabel ? label : ''"
    :rules="rules"
    :disable="disabled"
    clear-icon="close"
    dense
    clearable
    lazy-rules
    hide-bottom-space
    @input="updateValue($event.target.value)"
  >
    <template v-slot:prepend>
      <q-icon :name="icon" size="xs" v-if="icon" />
      <span
        class="text-sm mx-2"
        :style="{ width: inlineLabelWidth }"
        v-if="inlineLabel"
      >
        {{ label }}
      </span>
    </template>

    <template v-slot:append v-if="password">
      <q-icon
        :name="showPasswordIcon"
        @click="togglePassword"
        class="cursor-pointer"
        size="xs"
      />
    </template>
  </q-input>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'FormInput',
  inheritAttrs: true,
  props: {
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: null
    },
    password: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inlineLabel: {
      type: Boolean,
      default: false
    },
    inlineLabelWidth: {
      type: String,
      default: 'auto'
    }
  },
  setup(props, { emit }) {
    const inputType = ref(props.password ? 'password' : props.type)
    const showPassword = ref(false)
    const showPasswordIcon = ref('visibility')

    const inputClass = computed(() => {
      return ['full-width']
    })

    const inputInnerClass = computed(() => {
      return {
        textAreaContainer: props.type === 'textarea'
      }
    })

    const togglePassword = () => {
      showPassword.value = !showPassword.value
      inputType.value = showPassword.value ? 'text' : 'password'
      showPasswordIcon.value = showPassword.value
        ? 'visibility_off'
        : 'visibility'
    }

    const updateValue = value => {
      emit('input', value)
    }

    return {
      inputType,
      showPassword,
      showPasswordIcon,
      inputClass,
      inputInnerClass,
      togglePassword,
      updateValue
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.textAreaContainer) {
  min-height: 125px !important;
}
</style>
