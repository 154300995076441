<template>
  <q-select
    clear-icon="close"
    :class="inputClass"
    :new-value-mode="valueMode"
    :label="label"
    :disable="disabled"
    :rules="rules"
    dense
    clearable
    hide-bottom-space
    hide-dropdown-icon
    multiple
    use-chips
    use-input
  >
    <template v-slot:append v-if="withButton">
      <q-btn rounded dense flat :icon="btnIcon" @click="createValue" />
      <Tooltip :text="btnLabel" />
    </template>
  </q-select>
</template>

<script>
import { computed } from 'vue'

import Tooltip from '@/components/Tooltip'

export default {
  name: 'FormTag',
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    },
    tags: {
      type: Array,
      default: () => []
    },
    unique: {
      type: Boolean,
      default: true
    },
    withButton: {
      type: Boolean,
      default: false
    },
    btnLabel: {
      type: String,
      default: null
    },
    btnIcon: {
      type: String,
      default: null
    }
  },
  components: { Tooltip },
  setup(props, { emit }) {
    const inputClass = computed(() => {
      return ['full-width']
    })
    const valueMode = computed(() => {
      return props.unique ? 'add-unique' : 'add'
    })

    const createValue = () => {
      emit('addTag')
    }

    return {
      inputClass,
      valueMode,
      createValue
    }
  }
}
</script>
