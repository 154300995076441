<template>
  <q-file
    :class="inputClass"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :multiple="multiple"
    :filter="filter"
    :counter-label="counterLabel"
    clear-icon="close"
    counter
    append
    outlined
    dense
    clearable
    lazy-rules
    hide-bottom-space
    @rejected="onFileError"
    @update:model-value="updateValue"
  >
    <template v-slot:prepend>
      <q-icon name="attach_file" size="xs" />
    </template>
  </q-file>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'FormFile',
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const inputClass = computed(() => {
      return ['full-width']
    })

    const updateValue = value => {
      emit('onFileUpdate', value)
    }

    const onFileError = rejectedEntries => {
      emit('onFileError', rejectedEntries)
    }

    const counterLabel = ({ totalSize, filesNumber, maxFiles }) => {
      if (props.multiple) {
        return `${filesNumber} files of ${maxFiles} | ${totalSize}`
      } else {
        return totalSize === 'NaNB' ? '' : totalSize
      }
    }

    return {
      inputClass,
      updateValue,
      onFileError,
      counterLabel
    }
  }
}
</script>
